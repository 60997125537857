import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../../components/Seo"
import Layout from "../../components/layout/Layout"
import GetUpdates from "../../components/GetUpdates"

const TeamPage = ({ data }) => {
  const [dataset, setDataset] = useState(data.all.nodes);
  const [showBoard, setShowBoard] = useState(false);
  // reduce to array of unique objects
  const staff = [...new Map(data.staff.nodes.map(node => [node.contentful_id, node])).values()]
  const fellows = [...new Map(data.fellows.nodes.map(node => [node.contentful_id, node])).values()]
  const board = [...new Map(data.board.nodes.map(node => [node.contentful_id, node])).values()]

  const handleClick = (event) => {
    let choice = event.target.textContent
    setDataset(choice)
    switch (choice) {
      case "Staff":
        setDataset(staff)
        setShowBoard(false)
        break
      case "Fellows and Affiliates":
        setDataset(fellows)
        setShowBoard(false)
        break
      case "Advisory Board":
        setDataset(board)
        setShowBoard(true)
        break
      case "All":
        setDataset(data.all.nodes)
        setShowBoard(false)
        break
      default:
        setDataset(data.all.nodes)
    }
  }

  return (
    <>
      <Seo title="Lab Alumni (2018-2023)" bodyClass="page__team layout__team presentation" />
      <Layout>
        <>
          <div className="u__spacing s__light">
            <h2 className="h1 mb-0 text-center">Lab Alumni (2018-2023)</h2>
          </div>
          <div className="row team__filters justify-content-center u__spacing s__light">
            <button className="btn btn-primary" onClick={handleClick}>All</button>
            <button className="btn btn-primary" onClick={handleClick}>Staff</button>
            <button className="btn btn-primary" onClick={handleClick}>Fellows and Affiliates</button>
            <button className="btn btn-primary" onClick={handleClick}>Advisory Board</button>
          </div>
          <div className="u__spacing team s__light">
            <div className="container d-flex flex-wrap justify-content-center" aria-live="polite">
              {showBoard &&
                <>
                  <p>Our Advisory Board was composed of senior leaders from federal and state government, higher education, nonprofit, and technology sectors. The Board provided strategic advice on The Policy Lab’s core mission, research and project agenda, and cultivates relationships with key stakeholders to amplify the impact of The Lab.</p>
                  <ul>
                    <li>Angela Bannerman Ankoma, Vice President and Executive Director - Equity Leadership Initiative, Rhode Island Foundation</li>
                    <li>Diana Epstein, Evidence Team Lead, U.S. Office of Management and Budget (OMB)</li>
                    <li>Torey Malatia, President and General Manager, The Public’s Radio (RI NPR)</li>
                    <li>Shankar Prasad, Vice President for Academic Innovation and Deputy Provost, Brown University</li>
                    <li>Sabrina Solares-Hand, Chief Operating Officer, City of Providence</li>
                    <li>Heather West, Head of Privacy Policy, Facebook</li>
                  </ul>
                </>
              }
              {dataset.map(node => {
                const headshot = getImage(node.headshot)
                return (
                  <div className="team__member col-6 col-md-4 col-lg-3 u__vspacing-b" key={node.contentful_id}>
                    <Link to={`/about/team/${node.slug}`} className="team__link">
                      <div className="team__photo">
                        <GatsbyImage
                          image={headshot}
                          alt={`${node.name} headshot`}
                          aria-hidden="true"
                        />
                      </div>
                      <p>
                        <span className="team__name h5">{node.name}</span>
                      </p>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </>
        <GetUpdates sectionClass="s__light-splatter" />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    all: allContentfulTeamMember(
      sort: {fields: [firstName, lastName]}
    ) {
      nodes {
        ...TeamMemberInfo
      }
    }
    staff: allContentfulTeamMember(
      sort: {fields: [firstName, lastName]}
      filter: {position: {elemMatch: {group: {eq: "Staff"}}}}
    ) {
      nodes {
        ...TeamMemberInfo
      }
    }
    fellows: allContentfulTeamMember(
      sort: {fields: [firstName, lastName]}
      filter: {position: {elemMatch: {group: {eq: "Fellows and Affiliates"}}}}
    ) {
      nodes {
        ...TeamMemberInfo
      }
    }
    board: allContentfulTeamMember(
      sort: {fields: [firstName, lastName]}
      filter: {position: {elemMatch: {group: {eq: "Advisory Board"}}}}
    ) {
      nodes {
        ...TeamMemberInfo
      }
    }
  }

  fragment TeamMemberInfo on ContentfulTeamMember {
    contentful_id
    headshot {
      gatsbyImageData
    }
    name
    position {
      title
    }
    slug
  }
`

export default TeamPage
